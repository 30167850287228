/* lexend */
@font-face {
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 300 700;
    src:
        local('Readex Pro'),
        url('/assets/fonts/readexpro-variable.ttf') format('truetype');
    font-display: swap;
}

.__font-400 {
    font-family: 'Readex Pro';
    font-weight: 400;
}

.__font-500 {
    font-family: 'Readex Pro';
    font-weight: 500;
}

.__font-700 {
    font-family: 'Readex Pro';
    font-weight: 700;
}

/* customizations need for emoji fonts */
@font-face {
    font-family: 'Segoe UI Emoji';
    src: local('Segoe UI Emoji');
    ascent-override: 94%;
}
@font-face {
    font-family: 'Segoe UI Symbol';
    src: local('Segoe UI Symbol');
    ascent-override: 94%;
}

$fa-font-path: '/assets/fonts';
$fa-font-display: swap;
@import '@fortawesome/fontawesome-pro/scss/fontawesome';
@import '@fortawesome/fontawesome-pro/scss/brands';
@import '@fortawesome/fontawesome-pro/scss/light';
@import '@fortawesome/fontawesome-pro/scss/regular';
@import '@fortawesome/fontawesome-pro/scss/solid';
@import '@fortawesome/fontawesome-pro/scss/duotone';
@import '@fortawesome/fontawesome-pro/scss/duotone-icons';
