@use 'sass:map';
@use 'sass:math';
@use './constants';

/**
 * Calculate the luminance for a color.
 * See https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-tests
 */
@function luminance($color) {
    $red: nth(constants.$linear-channel-values, red($color) + 1);
    $green: nth(constants.$linear-channel-values, green($color) + 1);
    $blue: nth(constants.$linear-channel-values, blue($color) + 1);

    @return 0.2126 * $red + 0.7152 * $green + 0.0722 * $blue;
}

/**
* Calculate the contrast ratio between two colors.
* See https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-tests
*/
@function contrast($back, $front) {
    $backLum: luminance($back) + 0.05;
    $foreLum: luminance($front) + 0.05;

    @return math.div(max($backLum, $foreLum), min($backLum, $foreLum));
}

/**
 * Determine whether to use dark or light text on top of given color.
 * Returns black for dark text and white for light text.
 */
@function contrast-color($color) {
    $lightContrast: contrast($color, white);
    $darkContrast: contrast($color, black);

    @if ($lightContrast > $darkContrast) {
        @return white;
    } @else {
        @return black;
    }
}
@function text-contrast-color($color) {
    $lightContrast: contrast($color, white);
    $darkContrast: contrast($color, black);

    @if ($lightContrast > $darkContrast) {
        @return white;
    } @else {
        @return rgba(black, 0.87);
    }
}

@function tetrad($base-color) {
    $hue: hue($base-color);
    $saturation: saturation($base-color);
    $lightness: lightness($base-color);
    $colors: $base-color;
    $colors: append($colors, hsl(($hue + 90) % 360, $saturation, $lightness), comma);
    $colors: append($colors, hsl(($hue + 180) % 360, $saturation, $lightness), comma);
    $colors: append($colors, hsl(($hue + 270) % 360, $saturation, $lightness), comma);

    @return $colors;
}

@function mat-palette-generate($color) {
    $baseLight: #ffffff;
    $baseDark: rgb(
        math.div(red($color) * red($color), 255),
        math.div(green($color) * green($color), 255),
        math.div(blue($color) * blue($color), 255)
    );
    $baseTetrad: tetrad($color);
    $paletteColors: (
        50: mix($color, $baseLight, 12%),
        100: mix($color, $baseLight, 30%),
        200: mix($color, $baseLight, 50%),
        300: mix($color, $baseLight, 70%),
        400: mix($color, $baseLight, 85%),
        500: $color,
        600: mix($color, $baseDark, 87%),
        700: mix($color, $baseDark, 70%),
        800: mix($color, $baseDark, 54%),
        900: mix($color, $baseDark, 25%),
        A100: lighten(saturate(mix(nth($baseTetrad, 4), $baseDark, 15%), 80%), 65%),
        A200: lighten(saturate(mix(nth($baseTetrad, 4), $baseDark, 15%), 80%), 55%),
        A400: lighten(saturate(mix(nth($baseTetrad, 4), $baseDark, 15%), 100%), 45%),
        A700: lighten(saturate(mix(nth($baseTetrad, 4), $baseDark, 15%), 100%), 40%)
    );
    $basePalette: map.merge(
        $paletteColors,
        (
            contrast: (
                50: contrast-color(map.get($paletteColors, 50)),
                100: contrast-color(map.get($paletteColors, 100)),
                200: contrast-color(map.get($paletteColors, 200)),
                300: contrast-color(map.get($paletteColors, 300)),
                400: contrast-color(map.get($paletteColors, 400)),
                500: contrast-color(map.get($paletteColors, 500)),
                600: contrast-color(map.get($paletteColors, 600)),
                700: contrast-color(map.get($paletteColors, 700)),
                800: contrast-color(map.get($paletteColors, 800)),
                900: contrast-color(map.get($paletteColors, 900)),
                A100: contrast-color(map.get($paletteColors, A100)),
                A200: contrast-color(map.get($paletteColors, A200)),
                A400: contrast-color(map.get($paletteColors, A400)),
                A700: contrast-color(map.get($paletteColors, A700))
            )
        )
    );
    $result: map.merge(
        $basePalette,
        (
            default: map.get($basePalette, 500),
            lighter: map.get($basePalette, 100),
            darker: map.get($basePalette, 700),
            text: map.get($basePalette, 500),
            default-contrast: map.get(map.get($basePalette, contrast), 500),
            lighter-contrast: map.get(map.get($basePalette, contrast), 100),
            darker-contrast: map.get(map.get($basePalette, contrast), 700)
        )
    );

    // For each hue in the palette, add a "-contrast" color to the map.
    @each $hue, $color in $basePalette {
        $result: map.merge(
            $result,
            (
                '#{$hue}-contrast': map.get(map.get($basePalette, contrast), $hue)
            )
        );
    }

    @return $result;
}

@function hex-to-rgb($color) {
    @if ($color == null) {
        @return null;
    }
    @return red($color) green($color) blue($color);
}

@function hex-to-rgba($color) {
    @if ($color == null) {
        @return null;
    }
    @return red($color) green($color) blue($color) unquote('/') alpha($color);
}
