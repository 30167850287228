@use 'sass:map';
@use '@angular/material' as mat;
@use './functions';

/* mixin for transitions */
@mixin transitionable($style, $time, $ease) {
    -webkit-transition: $style $time $ease;
    -moz-transition: $style $time $ease;
    -o-transition: $style $time $ease;
    transition: $style $time $ease;
}
/* mixin for multiline */
@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white) {
    overflow: hidden;
    position: relative;
    line-height: $lineHeight;
    max-height: $lineHeight * $lineCount;
    text-align: justify;
    margin-right: -1em;
    padding-right: 1em;
    &:before {
        content: '...';
        position: absolute;
        right: 0;
        bottom: 0;
    }
    &:after {
        content: '';
        position: absolute;
        right: 0;
        width: 1em;
        height: 1em;
        margin-top: 0.2em;
        background: $bgColor;
    }
}
/* mixin for custom mat-icon colors */
@mixin matIconTheme($themeName, $color) {
    .mat-icon {
        &.mat-#{$themeName} {
            color: $color;
        }
    }
}
/* mixin for custom mat-button colors */
@mixin matButtonTheme($themeName, $color) {
    $contrast: functions.contrast-color($color);
    $text-color: functions.text-contrast-color($color);

    .mat-mdc-button {
        &.mat-#{$themeName} {
            --mdc-text-button-label-text-color: #{$color};
            --mat-mdc-button-persistent-ripple-color: #{$color};
            --mat-mdc-button-ripple-color: #{rgba($color, 0.1)};

            &[disabled] {
                color: rgba(black, 0.26);
            }
        }
    }

    .mat-mdc-icon-button {
        &.mat-#{$themeName} {
            --mdc-icon-button-icon-color: #{$color};
            --mat-mdc-button-persistent-ripple-color: #{$color};
            --mat-mdc-button-ripple-color: #{rgba($color, 0.1)};
        }
    }

    .mat-mdc-outlined-button {
        &.mat-#{$themeName} {
            --mdc-outlined-button-label-text-color: #{$color};
            --mat-mdc-button-persistent-ripple-color: #{$color};
            --mat-mdc-button-ripple-color: #{rgba($color, 0.1)};

            &[disabled] {
                color: rgba(black, 0.26);
            }
        }
    }

    .mat-mdc-unelevated-button {
        &.mat-#{$themeName} {
            --mdc-filled-button-container-color: #{$color};
            --mdc-filled-button-label-text-color: #{$text-color};
            --mdc-filled-button-disabled-label-text-color: rgba(black, 0.26);
        }
    }

    .mat-mdc-raised-button {
        &.mat-#{$themeName} {
            --mdc-protected-button-container-color: #{$color};
            --mdc-protected-button-label-text-color: #{$text-color};
            --mdc-protected-button-disabled-label-text-color: rgba(black, 0.26);
        }
    }

    .mat-mdc-fab,
    .mat-mdc-mini-fab {
        &.mat-#{$themeName} {
            --mdc-fab-container-color: #{$color};
            --mdc-fab-icon-color: #{$text-color};
            --mat-mdc-fab-color: #{$text-color};
        }
    }
}
/* mixin for custom mat-button colors */
@mixin matButtonGradientTheme($themeName, $color, $gradient) {
    $contrast: functions.contrast-color($color);
    $text-color: functions.text-contrast-color($color);

    .mat-mdc-button {
        &.mat-#{$themeName}-gradient {
            --mdc-text-button-label-text-color: #{$color};
            --mat-mdc-button-persistent-ripple-color: #{$color};
            --mat-mdc-button-ripple-color: #{rgba($color, 0.1)};

            &[disabled] {
                color: rgba(black, 0.26);
            }
        }

        &.mat-#{$themeName}-gradient {
            &[disabled] {
                color: rgba(black, 0.26);
            }
        }
        &.mat-#{$themeName}-gradient .mat-button-focus-overlay {
            background-image: $gradient;
        }
    }

    .mat-mdc-icon-button {
        &.mat-#{$themeName}-gradient {
            --mdc-icon-button-icon-color: #{$color};
            --mat-mdc-button-persistent-ripple-color: #{$color};
            --mat-mdc-button-ripple-color: #{rgba($color, 0.1)};
        }
    }

    .mat-mdc-outlined-button {
        &.mat-#{$themeName}-gradient {
            --mdc-outlined-button-label-text-color: #{$color};
            --mat-mdc-button-persistent-ripple-color: #{$color};
            --mat-mdc-button-ripple-color: #{rgba($color, 0.1)};

            &[disabled] {
                color: rgba(black, 0.26);
            }
        }
    }

    .mat-mdc-unelevated-button {
        &.mat-#{$themeName}-gradient {
            --mdc-filled-button-container-color: #{$color};
            --mdc-filled-button-label-text-color: #{$text-color};
            --mdc-filled-button-disabled-label-text-color: rgba(black, 0.26);
            background-image: $gradient;

            &[disabled] {
                background-image: linear-gradient(
                    to right bottom,
                    rgba(black, 0),
                    rgba(black, 0.03),
                    rgba(black, 0.06),
                    rgba(black, 0.09),
                    rgba(black, 0.12)
                );
            }
        }
    }

    .mat-mdc-raised-button {
        &.mat-#{$themeName}-gradient {
            --mdc-protected-button-container-color: #{$color};
            --mdc-protected-button-label-text-color: #{$text-color};
            --mdc-protected-button-disabled-label-text-color: rgba(black, 0.26);
            background-image: $gradient;

            &[disabled] {
                background-image: linear-gradient(
                    to right bottom,
                    rgba(black, 0),
                    rgba(black, 0.03),
                    rgba(black, 0.06),
                    rgba(black, 0.09),
                    rgba(black, 0.12)
                );
            }
        }
    }

    .mat-mdc-fab,
    .mat-mdc-mini-fab {
        &.mat-#{$themeName}-gradient {
            --mdc-fab-container-color: #{$color};
            --mdc-fab-icon-color: #{$text-color};
            --mat-mdc-fab-color: #{$text-color};
            background-image: $gradient;

            &[disabled] {
                background-image: linear-gradient(
                    to right bottom,
                    rgba(black, 0),
                    rgba(black, 0.03),
                    rgba(black, 0.06),
                    rgba(black, 0.09),
                    rgba(black, 0.12)
                );
            }
        }
    }
}
/* mixin for custom mat-tab-group colors */
@mixin matTabsThemeBackground($themeName, $color) {
    $contrast: functions.contrast-color($color);
    $text-color: functions.text-contrast-color($color);

    .mat-mdc-tab-group,
    .mat-mdc-tab-nav-bar {
        // Set background color of the tabs and override focus color
        &.mat-background-#{$themeName} {
            // Set background color for the tab group
            .mat-mdc-tab-header,
            .mat-mdc-tab-links,
            .mat-mdc-tab-header-pagination,
            .mat-mdc-tab,
            .mat-mdc-tab-link {
                background-color: $color;
            }

            // Set labels to contrast against background
            .mat-mdc-tab-label,
            .mat-mdc-tab-link {
                color: $text-color;

                &.mat-tab-disabled {
                    color: rgba($contrast, 0.4);
                }
            }

            // Set pagination chevrons to contrast background
            .mat-mdc-tab-header-pagination-chevron {
                border-color: $contrast;
            }

            .mat-mdc-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
                border-color: rgba($contrast, 0.4);
            }

            // Set ripples color to be the contrast color of the new background. Otherwise the ripple
            // color will be based on the app background color.
            .mat-mdc-ripple-element {
                background-color: rgba($contrast, 0.12);
            }
        }
    }
}

@mixin matTabsThemeForeground($themeName, $color) {
    .mat-mdc-tab-group,
    .mat-mdc-tab-nav-bar {
        // Set the foreground color of the tabs
        &.mat-#{$themeName} {
            .mat-mdc-tab,
            .mat-mdc-tab-link {
                &:not(.mat-mdc-tab-disabled) {
                    --mat-tab-header-active-label-text-color: #{$color};
                    --mat-tab-header-active-focus-label-text-color: #{$color};
                    --mat-tab-header-active-hover-label-text-color: #{$color};
                    --mdc-tab-indicator-active-indicator-color: #{$color};
                    --mat-tab-header-active-focus-indicator-color: #{$color};
                    --mat-tab-header-active-hover-indicator-color: #{$color};
                }
            }
        }
    }
}

@mixin matProgressBarTheme($themeName, $color) {
    .mat-mdc-progress-bar {
        &.mat-#{$themeName} {
            --mdc--linear-progress--active-indicator-color: #{$color};
        }
    }
}
