@use 'sass:map';
@use '@angular/material' as mat;
@use './functions';
@use './variables';

$mymahi-typography: mat.m2-define-typography-config(
    $font-family: variables.$mymahi-font-family,
    $button: mat.m2-define-typography-level(14px, 14px, 500, $letter-spacing: normal)
);

$mymahi-app-theme-dark: mat.m2-define-dark-theme(
    (
        color: (
            primary: variables.$mymahi-app-primary,
            accent: variables.$mymahi-app-accent,
            warn: variables.$mymahi-app-warn
        ),
        typography: $mymahi-typography,
        density: -1
    )
);

$mymahi-app-theme-light: mat.m2-define-light-theme(
    (
        color: (
            primary: variables.$mymahi-app-primary,
            accent: variables.$mymahi-app-accent,
            warn: variables.$mymahi-app-warn
        ),
        density: -1
    )
);

/* dont use for dark/light themed apps */
$mymahi-app-theme: mat.m2-define-light-theme(
    (
        color: (
            primary: variables.$mymahi-app-primary,
            accent: variables.$mymahi-app-accent,
            warn: variables.$mymahi-app-warn
        ),
        typography: $mymahi-typography,
        density: 0
    )
);
