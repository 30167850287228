/* You can add global styles to this file, and also import other style files */
@use 'sass:map';
@use '@angular/material' as mat;
@use 'customizations';
@use 'fonts';
@use 'mixins';
@use 'theme';
@use 'variables';

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    height: 100%;
    width: 100%;
    position: relative;

    --safe-area-inset-top: env(safe-area-inset-top, 0px);
    --safe-area-inset-right: env(safe-area-inset-right, 0px);
    --safe-area-inset-bottom: env(safe-area-inset-bottom, 0px);
    --safe-area-inset-left: env(safe-area-inset-left, 0px);
}

body,
public-root {
    display: block;
    margin: 0;
    width: 100%;
    min-height: 1080px;
    background-color: map.get(variables.$colors, 'white');

    &::-webkit-scrollbar {
        display: none;
    }
}

* {
    box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    user-select: none;
    -webkit-touch-callout: none;
}

input,
textarea,
select {
    user-select: auto;
    -moz-user-select: text;
    -webkit-touch-callout: none;
}

a,
button,
input,
textarea,
select {
    touch-action: manipulation;
}

input:active,
input:focus,
textarea:active,
textarea:focus,
select:active,
select:focus {
    outline: none;
}

.size-mobile {
    & input,
    & textarea,
    & input:focus,
    & textarea:focus {
        font-size: 16px;
    }
}

.noscript-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
}

.noscript-message-container {
    flex: 0 0 auto;
    background: rgba(255, 255, 255, 0.8);
    min-height: 300px;
    padding: 16px;
    margin: 16px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
@include mat.elevation-classes();
@include mat.app-background();
@include mat.typography-hierarchy(theme.$mymahi-typography);

// Include the default theme styles.
@include mat.all-component-themes(theme.$mymahi-app-theme);

@include customizations.shared;
@include mixins.matTabsThemeBackground('white', #ffffff);

/* Background */
public-root {
    .size-web & {
        background: url(/assets/background/bg-home-web.svg) repeat top center;
        background-size: cover;
        background-position-y: -700px;

        &.features {
            background: url(/assets/background/bg-features-web.svg) repeat top center;
            background-size: cover;
            background-position-y: 50px;
        }

        &.pricing {
            background: url(/assets/background/bg-pricing-web.svg) repeat top center;
            background-size: cover;
        }

        &.about-us {
            background: url(/assets/background/bg-about-web.svg) repeat top center;
            background-size: cover;
            background-position-y: -100px;
        }

        &.programmes {
            background: url(/assets/background/bg-programmes-web.svg) repeat top center;
            background-size: cover;
            background-position-y: -100px;
        }
    }

    .size-tablet & {
        background: url(/assets/background/bg-home-web.svg) repeat top center;
        background-size: contain;
        background-position-y: -100px;

        &.features {
            background: url(/assets/background/bg-features-web.svg) repeat top center;
            background-size: contain;
            background-position-y: 50px;
        }

        &.pricing {
            background: url(/assets/background/bg-pricing-web.svg) repeat top center;
            background-size: cover;
        }

        &.about-us {
            background: url(/assets/background/bg-about-web.svg) repeat top center;
            background-size: cover;
        }

        &.programmes {
            background: url(/assets/background/bg-programmes-web.svg) repeat top center;
            background-size: cover;
        }
    }

    .size-mobile & {
        background: url(/assets/background/bg-home-mobile.svg) repeat top center;
        background-size: cover;

        &.features {
            background: url(/assets/background/bg-features-mobile.svg) repeat top center;
            background-size: cover;
            background-position-y: 50px;
        }

        &.pricing {
            background: url(/assets/background/bg-pricing-mobile.svg) repeat top center;
            background-size: cover;
        }

        &.about-us {
            background: url(/assets/background/bg-about-mobile.svg) repeat top center;
            background-size: contain;
        }

        &.programmes {
            background: url(/assets/background/bg-programmes-mobile.svg) repeat top center;
            background-size: cover;
        }
    }
}

/* Helper classes for page layout */
.landing-container {
    width: 100%;
    margin: 0 auto;
    padding: 0;

    .size-mobile & {
        padding-top: var(--safe-area-inset-top);
        padding-bottom: var(--safe-area-inset-bottom);
        padding-right: var(--safe-area-inset-right);
        padding-left: var(--safe-area-inset-left);
    }

    & .page-container {
        max-width: 1080px;
        width: 100%;
        padding: 30px 30px 150px 30px;
        margin: 0 auto;

        .size-tablet & {
            max-width: 808px;
            padding: 60px 60px 150px 60px;
        }

        .size-mobile & {
            width: 100%;
            padding: 0 30px 100px 30px;
        }
    }
}

.page-title {
    font-family: variables.$mymahi-font-family;
    color: variables.$color-primary;
    font-size: 55px;
    font-weight: 700;
    line-height: 70px;

    .size-tablet & {
        font-size: 45px;
        line-height: 50px;
    }

    .size-mobile & {
        font-size: 35px;
        line-height: 40px;
    }
}

.page-introduction {
    font-family: variables.$mymahi-font-family;
    font-size: 0.875rem;
    line-height: 1.71;
    color: map.get(variables.$colors, 'darkgrey');
    margin: 0;

    .size-mobile & {
        display: none;
    }
}

/**
* Material Button customizations
*/
.mat-mdc-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button,
.mat-mdc-raised-button {
    line-height: 2rem !important; // Override the default material design changes to the line-height
    border-radius: 1.125rem !important;
    & .mdc-button__label {
        width: 100%;
        text-align: center;
    }

    & .mat-icon {
        font-size: inherit;
        height: inherit;
        line-height: inherit;
        width: inherit;
    }
}

.mat-mdc-icon-button,
.mat-mdc-fab,
.mat-mdc-mini-fab {
    & .mat-icon {
        height: 40px;
        line-height: 1 !important;
    }
}

.mat-stroked-color {
    &.mat-mdc-outlined-button:not([disabled]) {
        border-color: currentColor;
    }
}

/**
* Title customizations
*/
.title {
    font-size: 40px;
    line-height: 55px;
    font-weight: 700;
    line-height: normal;
    color: variables.$color-primary;

    .size-tablet & {
        font-size: 35px;
    }

    .size-mobile & {
        font-size: 30px;
    }
}

.item-title {
    font-size: 30px;
    font-weight: 700;
    line-height: normal;
    color: variables.$color-primary;

    .size-tablet & {
        font-size: 24px;
    }

    .size-mobile & {
        font-size: 16px;
    }
}

.item-sub-title {
    font-size: 32px;
    font-weight: 700;
    line-height: normal;
    color: variables.$color-primary;

    .size-tablet & {
        font-size: 24px;
    }

    .size-mobile & {
        font-size: 20px;
    }
}

.description {
    font-size: 20px;
    font-weight: 400;
    line-height: 130%;
    color: map.get(variables.$colors, 'darkgrey');

    .size-tablet & {
        font-size: 1rem;
    }

    .size-mobile & {
        font-size: 14px;
    }
}

/**
* Buttons customizations
*/
.link-button {
    min-width: 20px;
    min-height: 40px;
    text-align: center;
    padding: 0.5rem;
    font-size: 1rem !important;
    font-weight: 400;
    color: map.get(variables.$colors, 'darkgrey') !important;
    border-radius: 20px !important;

    &.gradient {
        color: map.get(variables.$colors, 'white') !important;
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
        font-weight: 500;
    }
}
/* cover-image */
.cover-image {
    width: 100%;
    height: 240px;
    object-fit: cover;
    border-radius: 30px;

    .size-mobile & {
        height: 120px;
        border-radius: 20px;
    }
}

/* gradient divider */
.gradient-divider {
    width: 100%;
    height: 0.5rem;
    background: linear-gradient(
        90deg,
        rgba(255, 135, 0, 1) 0%,
        rgba(214, 48, 108, 1) 25%,
        rgba(111, 0, 183, 1) 45%,
        rgba(100, 32, 201, 1) 62%,
        rgba(25, 175, 250, 1) 100%
    );
}

/**
* Card customizations
*/
.shadow-card {
    height: auto !important;
    width: 100%;
    padding: 40px !important;
    background-color: map.get(variables.$colors, 'white');
    border-radius: 30px;
    box-shadow: 0px 0px 21px 20px rgba(234, 235, 240, 0.5);

    &.feature-card,
    &.price-card {
        background-color: #ffffff;
        padding: 20px !important;
        box-shadow: 0px 0px 10px 10px rgba(234, 235, 240, 0.5);

        .size-mobile & {
            padding: 20px;
            border-radius: 20px;
        }
    }

    .size-mobile & {
        padding: 20px;
        border-radius: 20px;
    }

    &.programme-card {
        background-color: #ffffff;
        padding: 0px !important;
        height: 100% !important;
        box-shadow: 0px 0px 10px 10px rgba(234, 235, 240, 0.5);

        .size-mobile & {
            padding: 0px !important;
            border-radius: 20px;
        }
    }

    .size-mobile & {
        padding: 20px;
        border-radius: 20px;
    }
}

.grey-card {
    width: 100%;
    padding: 60px;
    background-color: #f5f5f5;
    border-radius: 30px;
    position: relative;

    .size-mobile & {
        padding: 20px;
        border-radius: 20px;
    }
}

/* testimonial */

.testimonial-detail {
    & .testimonial-logo {
        width: 65px;
        height: 65px;

        .size-mobile & {
            width: 50px;
            height: 50px;
        }

        & .testimonial-logo-image {
            border-radius: 30px;
            object-fit: cover;
            height: 100%;

            .size-mobile & {
                border-radius: 25px;
            }
        }
    }

    & .testimonial-name {
        font-size: 20px;
        font-weight: 700;
        line-height: normal;
        color: variables.$color-primary;

        .size-mobile & {
            font-size: 16px;
        }
    }

    & .testimonial-position {
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
        color: map.get(variables.$colors, 'black');

        .size-mobile & {
            font-size: 12px;
        }
    }

    & .testimonial-organisation {
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        color: map.get(variables.$colors, 'darkgrey');

        .size-mobile & {
            font-size: 12px;
        }
    }

    & .testimonial-location {
        max-width: fit-content;
        padding: 4px 8px;
        border-radius: 16px;
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
        color: map.get(variables.$colors, 'grey');

        &.nz {
            background-color: rgba(map.get(variables.$colors, 'green'), 0.15);
        }

        &.au {
            background-color: rgba(map.get(variables.$colors, 'orange'), 0.15);
        }

        .size-mobile & {
            font-size: 12px;
        }
    }
}

.testimonial-text {
    font-size: 18px;
    font-weight: 300;
    line-height: normal;
    color: map.get(variables.$colors, 'darkgrey');

    .size-mobile & {
        font-size: 14px;
    }
}

.testimonial-card {
    width: 100%;
    padding: 40px;
    background-color: #f5f5f5;
    border-radius: 30px;
    position: relative;

    .size-mobile & {
        padding: 20px;
        border-radius: 20px;
    }

    & .testimonial-profile {
        position: absolute;
        bottom: 30px;
        right: 10px;
        width: 35%;
    }
}

/**
* Material Input customizations
*/
.mat-mdc-form-field {
    & .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }
}

/**
* Tabs customizations
*/
.mat-mdc-tab-nav-bar {
    &.normal-tabs {
        border-radius: 12px;
        box-shadow: 0px 0px 21px 20px rgba(234, 235, 240, 0.5);
        width: 100%;

        &.price {
            width: calc(100% - 166px);

            .size-mobile & {
                width: 100%;
            }
        }
    }

    &.scrollable-tabs {
        .size-mobile & {
            & .mat-mdc-tab-list {
                overflow-x: auto;
            }
            & ::-webkit-scrollbar {
                display: none;
                width: none;
            }
        }
    }

    & .mat-mdc-tab-header-pagination {
        --mat-tab-header-with-background-background-color: #ffffff;
    }

    & .mat-mdc-tab-link {
        min-width: 140px;

        .size-mobile & {
            min-width: 120px;
        }
    }
}

/**
* Form field customizations
*/
mat-form-field.mat-form-field {
    & .mat-mdc-text-field-wrapper {
        & .mat-mdc-form-field-flex {
            & .mdc-notched-outline {
                & .mdc-notched-outline__leading,
                & .mdc-notched-outline__notch,
                & .mdc-notched-outline__trailing {
                    border-color: map.get(variables.$colors, 'linegrey');
                }
            }
        }
    }
}

/**
* Programme register button customizations
*/
.large-button {
    width: fit-content;
    min-height: 56px;
    font-size: 24px !important;
    border-radius: 28px !important;

    .size-mobile & {
        min-height: 40px;
        font-size: 16px !important;
        border-radius: 20px !important;
    }

    & span {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

/* End */
