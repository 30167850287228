@use 'sass:map';
@use './functions';

$mymahi-app-primary: functions.mat-palette-generate(#003050);
$mymahi-app-accent: functions.mat-palette-generate(#19affa);
$mymahi-app-warn: functions.mat-palette-generate(#f44336);
$mymahi-font-family: 'Readex Pro', sans-serif;

$color-primary: #003050;
$color-accent: #19affa;
$colors: (
    'warn': #f44336,
    'notification': #ff0000,
    'green': #6cc624,
    'blue': #19affa,
    'darkblue': #0047ab,
    'violet': #8566f8,
    'purple': #9f4eeb,
    'pink': #ff6289,
    'peach': #ee7465,
    'red': #ea1c0d,
    'orange': #ff9000,
    'darkorange': #ff8700,
    'yellow': #ffa500,
    'lightyellow': #fdd017,
    'brown': #844204,
    'white': #ffffff,
    'lightgrey': #f1f2f3,
    'linegrey': #d8d9dd,
    'textwhite': #e4e6eb,
    'textgrey': #b0b3b8,
    'grey': #838395,
    'darkgrey': #4d4d5b,
    'lightblack': #1c1c1d,
    'black': #000000
);

$gradient-primary: linear-gradient(to right, #003050, #003a5b, #004465, #004f6f, #005a79);
$gradient-accent: linear-gradient(to right, #19affa, #14a2ec, #0e95dd, #0788cf, #007bc1);
$gradients: (
    'green': linear-gradient(to right, #6cc624, #48bd49, #23b262, #00a773, #009a7d),
    'blue': linear-gradient(to right, #19affa, #14a2ec, #0e95dd, #0788cf, #007bc1),
    'red': linear-gradient(to right, #f77d73, #f46458, #f14e41, #ed3629, #ea1c0d),
    'peach': linear-gradient(to right, #ee7465, #ee7e5b, #ef8851, #ef9147, #f09c3c),
    'purple': linear-gradient(to right, #9f4eeb, #833bd0, #6728b5, #4b159b, #2e0082),
    'orange': linear-gradient(to right, #ff9000, #ef7900, #df6200, #d04d00, #bf3500),
    'darkorange': linear-gradient(to right, #ff8700, #d6306c, #6f00b7, #6420c9),
    'brown': linear-gradient(to right, #845932, #785434, #6b4e36, #5f4938, #524439),
    'yellow': linear-gradient(to right, #ecaa0c, #e0a008, #d59605, #c98d02, #be8300),
    'lightyellow': linear-gradient(to right, #fdd017, #f1b812, #e49d0b, #d98706, #cc6d00),
    'pink': linear-gradient(to right, #d6306c, #c4255d, #b11a4f, #9f0e41, #8d0034),
    'violet': linear-gradient(to right, #8566f8, #6e53d3, #5941b0, #44308d, #2d1d67),
    'grey': linear-gradient(to right, #808490, #777a86, #6f737f, #686b77, #5f626e),
    'darkgrey': linear-gradient(to right, #404450, #373b47, #2e323e, #252935, #1d212c)
);

$dark-theme-colors: (
    'text': white,
    'text-disabled': rgba(white, 0.5),
    'text-title': #e4e6eb,
    'text-sub-title': #b0b3b8,
    'text-contrast': #e4e6eb,
    'text-high-contrast': #ffffff,
    'text-high-contrast-reverse': #000000,
    'text-heading': #e4e6eb,
    'outline': rgba(white, 0.22),
    'background': #1c1c1d,
    'background-lightergrey': #424242,
    'background-grey': #424242,
    'background-contrast': #424242,
    'background-high-contrast': #000000
);

$light-theme-colors: (
    'text': rgba(black, 0.87),
    'text-disabled': rgba(black, 0.38),
    'text-title': #4d4d5b,
    'text-sub-title': #838395,
    'text-contrast': #000000,
    'text-high-contrast': #000000,
    'text-high-contrast-reverse': #ffffff,
    'text-heading': #003050,
    'outline': #d8d9dd,
    'background': #ffffff,
    'background-lightergrey': #f4f6f8,
    'background-grey': #d8d9dd,
    'background-contrast': #ffffff,
    'background-high-contrast': #ffffff
);
