@use 'sass:math';
@use 'sass:map';
@use '@angular/material' as mat;
@use './variables';
@use './mixins';

@mixin shared() {
    /* Customizations to standard tags */
    p,
    span {
        white-space: pre-wrap;
        word-break: break-word;
    }

    hr {
        background-color: rgba(128, 132, 144, 0.15);
        border: none;
        height: 1px;
    }

    /* Material Button themes */
    @each $name, $color in variables.$colors {
        @include mixins.matButtonTheme($name, $color);
        @include mixins.matButtonGradientTheme($name, $color, map.get(variables.$gradients, $name));
    }
    @include mixins.matButtonGradientTheme(primary, variables.$color-primary, variables.$gradient-primary);
    @include mixins.matButtonGradientTheme(accent, variables.$color-accent, variables.$gradient-accent);

    /* Material Dialog customizations */
    .mat-dialog-container {
        & .mat-dialog-content {
            .size-mobile & {
                max-height: none;
            }
        }
    }

    /* Material Icon customizations */
    @each $name, $color in variables.$colors {
        @include mixins.matIconTheme($name, $color);
    }

    mat-icon.mat-icon {
        font-size: 18px;
        text-transform: none;
        text-align: center;
        white-space: nowrap;
        word-wrap: normal;
        height: 24px;
        width: 24px;
        padding: 3px 0;
    }

    /* Material Progress Bar themes */
    @each $name, $color in variables.$colors {
        @include mixins.matProgressBarTheme($name, $color);
    }

    /* Material Ripple customizations */
    .mat-ripple {
        &.mat-ripple-container {
            // This is a helper container for use inside things like cards
            // which have problems when attaching ripples to them
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            contain: content;
        }

        & .mat-ripple-element {
            will-change: transform;
        }
    }

    /* Material Select customizations */

    .mat-mdc-select {
        & span {
            white-space: nowrap;
        }
    }

    /* Material Tabs themes */
    @each $name, $color in variables.$colors {
        @include mixins.matTabsThemeForeground($name, $color);
    }

    /* NGX File Drop customizations */
    ngx-file-drop {
        & .ngx-file-drop__drop-zone {
            position: relative;
            height: 100% !important;
            border: 1px dashed mat.m2-get-color-from-palette(mat.$m2-grey-palette, 500) !important;
            border-radius: 18px !important;
            background-color: rgba(map.get(variables.$colors, 'blue'), 0.1);

            & .ngx-file-drop__content {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                color: mat.m2-get-color-from-palette(variables.$mymahi-app-accent);

                & .ngx-file-drop__browse-btn {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    padding: 0;
                    border: 0;
                    background-color: transparent;
                    opacity: 0;
                    overflow: hidden;
                    cursor: pointer;
                }
            }
        }
    }

    /* Markdown customizations */
    markdown {
        & * {
            user-select: text;
        }

        & pre {
            white-space: pre-wrap;
            word-break: break-word;
        }

        & img {
            max-width: 100%;
        }
    }

    /* Material Button text color override */
    @each $name, $color in variables.$colors {
        .mat-mdc-unelevated-button,
        .mat-mdc-raised-button,
        .mat-mdc-fab,
        .mat-mdc-mini-fab {
            &:not(:disabled) {
                &.mat-#{$name} {
                    color: white;
                }
            }
        }
    }

    @each $name, $color in variables.$gradients {
        .mat-mdc-unelevated-button,
        .mat-mdc-raised-button,
        .mat-mdc-fab,
        .mat-mdc-mini-fab {
            &:not(:disabled) {
                &.mat-#{$name}-gradient {
                    color: white;
                }
            }
        }
    }
    .mat-mdc-unelevated-button,
    .mat-mdc-raised-button,
    .mat-mdc-fab,
    .mat-mdc-mini-fab {
        &:not(:disabled) {
            &.mat-primary-gradient,
            &.mat-accent-gradient {
                color: white;
            }
        }
    }

    .mat-mdc-button,
    .mat-mdc-icon-button,
    .mat-mdc-outlined-button,
    .mat-mdc-unelevated-button,
    .mat-mdc-raised-button,
    .mat-mdc-fab,
    .mat-mdc-mini-fab {
        &:disabled {
            &.mat-unthemed {
                color: rgb(var(--color-text-disabled));
            }
        }
    }
}
